import React, { Fragment, useState } from "react";
import styled from "styled-components";
// Components
import PricingTable from "../../Elements/PricingTable";
import planJson from './plan.json'
import FullButton from "../../Buttons/FullButton";
import Features from './features.json'
import '../../../style/plan.css'
import CheckMark from "../../../assets/svg/Checkmark";
import CloseRedIcon from "../../../assets/svg/CloseRedIcon";
import ShareIcon from "../../../assets/svg/Share";

export default function Pricing() {
  const defaultPlan = [
    {
      id: 'free',
      name: 'Free',
      price: '$0.00',
      original_price: 0,
      is_current_plan: 0
    },
    {
      id: 'plus',
      name: 'Plus',
      price: '$10.00',
      original_price: 10,
      is_current_plan: 0
    },
    {
      id: 'pro',
      name: 'Pro',
      price: '$20.00',
      original_price: 20,
      is_current_plan: 0
    }
  ]
  const [plans, setPlans] = useState(defaultPlan)

  // const handleCollapse = (key, name) => {
  //   const cuPlan = plan.map((item) => {
  //     if (item?.key === key) {
  //       const obj = item
  //       obj?.features?.map((ft) => {
  //         const feature = ft
  //         if (ft?.name === name) {
  //           feature['isCollapse'] = !ft?.isCollapse
  //         }
  //         return feature
  //       })
  //       return obj
  //     } else return item
  //   })
  //   setPlan(cuPlan)
  // }

  const getIcon = (planKey) => {
    let icon = ''
    switch(planKey) {
      case 'free':
        icon = 'roller'
      break;
      case 'plus':
        icon = 'monitor'
      break;
      case 'pro':
        icon = 'browser'
      break;
      default:
        icon = 'printer'
      break;
    }
    return icon;
  }

  const TitleRow = ({ label = "" }) => {
    return (
      <tr className="plan-title">
        <td colSpan={plans?.length + 1}>
          <h4 >
            <span className="text-uppercase">{label}</span>
          </h4>
        </td>
      </tr>
    )
  }

  const SubTitleRow = ({ label = "" }) => {
    return (
      <tr className="plan-subtitle">
        <td colSpan={plans?.length + 1}>
          <h4 variant='headingMd' tone='base'>{label}</h4>
        </td>
      </tr>
    )
  }

  const Yes = () => {
    return (<CheckMark />)
  }
  const No = () => {
    return (<CloseRedIcon />)
  }

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          {/* <TablesWrapper className="flexSpaceNull">
            {
              plan.map((Plan, index) => (
                <Fragment key={`plan-${Plan?.key}`}>
                <TableBox>
                  <PricingTable
                    icon={getIcon(Plan?.key)}
                    price={Plan?.price}
                    title={Plan?.title}
                    text=""
                    offers={Plan?.features}
                    handleCollapse={(name) => handleCollapse(Plan.key, name)}
                    action={() => alert("clicked")}
                  />
                </TableBox>
                </Fragment>
              ))
            }
          </TablesWrapper> */}

          <PlanTableWrapper>
            <table className="plan-table" style={{ width: '100%' }}>
              <thead>
              <tr>
                    <th className="th-plan-title">
                      <div className="ft-box">
                        <div>
                          <h1 className="ft-title">Features</h1>
                          <span className="ft-subtitle">
                            🚀  <span>Free Plan available</span>
                          </span>
                        </div>
                      </div>
                    </th>
                    {
                      plans?.map((item, index) => {
                        return (
                          <th key={`nplan-${item?.name}`} className={`th-plan ${item?.is_current_plan ? 'active' : ''}`}>
                            <div>
                              <h4 className="head-plan-title">
                                <span style={{ textTransform: 'capitalize' }}>{item?.name}</span>
                              </h4>
                              <div style={{marginTop: '15px'}}>
                                <h1 style={{fontSize: '32px'}}>${item?.original_price?.toFixed(2)} </h1>
                                <span className="plan-type">/Month</span>
                              </div>
                            </div>
                            <div className="">
                              <button className="button free-trial" onClick={() => window.open("https://apps.shopify.com/notifyme", '_blank')}>7-days free trial</button>
                            </div>
                          </th>
                        )
                      })
                    }
                  </tr>
              </thead>
              <tbody className="plan-body">
              {
                    plans?.length > 0 &&
                    Features && Object.entries(Features).map(([key, features], ftIndex) => {
                      return features?.map((item) => {
                        return (
                          <Fragment key={`nfeature-${item?.label}`}>
                            {
                              item?.key === 'title' ? <TitleRow label={item?.label} /> :
                                item?.key === 'subtitle' ? <SubTitleRow label={item?.label} /> :
                                  (<>
                                    <tr className="">
                                      <td>
                                        <div>
                                          <div>
                                            {
                                              (item?.url && item.url != "") ?
                                                <a
                                                  href={item?.url}
                                                  target='_blank'
                                                >
                                                  <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    <h5>
                                                      {item?.label}
                                                    </h5>
                                                      <span style={{paddingLeft: '05px'}}>
                                                        <ShareIcon />
                                                      </span>
                                                    {
                                                      item?.small ?
                                                        <span>
                                                          {`(${item?.small})`}
                                                        </span>
                                                        : ""
                                                    }
                                                  </span>
                                                </a>
                                                :
                                                <span>
                                                  <h5>{item?.label}</h5>
                                                  {
                                                    item?.small ?
                                                      <h5>
                                                        {`(${item?.small})`}
                                                      </h5>
                                                      : ""
                                                  }
                                                </span>
                                            }
                                            {/* {
                                              item?.important ?
                                                <Text variant='headingSm' tone='critical'>*Need to add credit(balance)</Text>
                                                : ""
                                            } */}
                                          </div>
                                          {
                                            item?.description ?
                                              <a
                                                // onClick={() => {
                                                //   if (item?.key === 'notify_via_sms') setPricingModal(true)
                                                // }}
                                              >
                                                <h5>{item?.description}
                                                </h5>
                                              </a>
                                              : ''
                                          }
                                        </div>
                                      </td>
                                      {
                                        plans?.map((plan, index) => {
                                          return (
                                            <td key={`plan-${index}`} className={`${plan?.is_current_plan ? "active" : ""}`}>
                                              <div className="plan-td">
                                                <span>
                                                  {
                                                    plan?.id === 'custom' ? (
                                                      <Fragment>
                                                        {item?.custom?.deactive_for_now && ("-")}
                                                      </Fragment>
                                                    )
                                                      : (
                                                        item?.active?.includes(plan?.id?.toLowerCase()) ?
                                                        (item?.values ? item?.values?.find((value) => value?.plan === plan?.id)?.label : <Yes />)
                                                        : <No />
                                                      )
                                                  }
                                                </span>
                                              </div>
                                            </td>
                                          )
                                        })
                                      }
                                    </tr>
                                  </>
                                  )
                            }
                          </Fragment>
                        )
                      })
                    })
                  }
              </tbody>
            </table>
          </PlanTableWrapper>
          <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
        {/* <FullButton title="Buy" action={() => window.open("https://apps.shopify.com/notifyme", '_blank')} /> */}
      </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;

const PlanTableWrapper = styled.div
`
  width: 100%;
`




