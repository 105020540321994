import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

import LeftArrow from '../../assets/svg/LeftArrow';
import RightArrow from '../../assets/svg/RightArrow';

export default function TestimonialSlider() {
  const settings = {
    className: 'sliders',
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="These are helpful! messaged them a few times and they have helped. Love to see what clients would like to see back in stock i cant wait until there is a pre-order for certain times"
            author="The Endless Knot"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I had a small issue which was an oversight on my part and customer service was available and ready to assist me."
            author="HAIR BY MTB"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Great support, quick and helpfull. For now the only free app for my business, most of them only let 10 messages per month for free, this one goes till 50 messages for free."
            author="Pucshop"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Best app for product launch and out of stock products to notify on time.thanks to app team for integration and solution fixing on my website."
            author="Shankara India"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Great apps! I had a minor issue, but it was quickly resolved after connecting. Thanks for the support!"
            author="Wellbi"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="This is best application for 'Notify me' button. Their features are amazing and best to attract customers."
            author="farmdidi"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Great app, really helps our company with knowing what products are sought after, and allows us to order in more to meet demand! Very helpful and quick to respond to any questions we have! integrates fab with shopify and very easy set up!"
            author="The Fashion Shop"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Great customer support! I had an issue setting up the app and was able to resolve it immediately thanks to live chat with the support team."
            author="Kinnder"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Super helpful support, easy to use app and quick to answer any questions"
            author="Desoire"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Support went above to help find out what the coding error was we were having. Great support!"
            author="SameDaySupply"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
