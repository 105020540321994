import React, { Fragment } from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import CloseRedIcon from "../../assets/svg/CloseRedIcon";
import ChevronDown from "../../assets/svg/ChevronDown";
import ChevronUp from "../../assets/svg/ChevronUp";

export default function PricingTable({ icon, price, title, text,  offers, action, handleCollapse }) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  const Single = ({data, index, collpaseIcon}) => (
    <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
      <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
        {
          data?.type !== 'label' && 
          (
            <div style={{ minWidth: "20px", display: 'flex', alignItems: 'center' }}>
              {data.cheked ? <CheckMark /> : <CloseRedIcon />}
            </div>
          )}
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}} onClick={() => handleCollapse(data.name)}>
        <p className="font20 extraBold">{data.name}</p>
        {
          collpaseIcon &&
          <div>
            { data?.isCollapse ? <ChevronUp /> : <ChevronDown /> }
          </div>
        }
      </div>
    </div>
  )

  const Grouped = ({data, index, isCollapse}) => (
    <div style={{borderTop: '1px solid #92929260'}}>
        <Single data={data} index={index} collpaseIcon={true}/>
        <div style={{display: `${isCollapse ? 'block' : 'none'}`}} >
        {
          data?.data?.map((innerItem, indexed) => (
            <Fragment key={`features-${indexed}`}>
              <Single data={innerItem} index={indexed}/>
            </Fragment>
          ))
        }
        </div>
    </div>
  )

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        { title && <h4 className="font30 extraBold">{title}</h4> }
        { text && <p className="font13">{text}</p> }
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
            <Fragment key={`plan-${index}`}>
              { item?.type === 'label' ? <Grouped data={item} index={index} isCollapse={item?.isCollapse}/> : <Single data={item} index={index}/>  }
            </Fragment>
           
          ))
          : null}
      </div>
      
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;