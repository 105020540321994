import React from 'react'

export default function RightArrow(props) {
  return (
    <div { ...props }>
    <svg fill="#0B093B" width={20} height={20} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  
	 viewBox="0 0 455 455">
    <path d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M199.476,355.589
    	l-21.248-21.178L284.791,227.5L178.228,120.589l21.248-21.178L327.148,227.5L199.476,355.589z"/>
    </svg>
    </div>
  )
}
