import React from 'react'

export default function LeftArrow(props) {
  return (
    <div {...props}>
    <svg fill="#0B093B" width={20} height={20} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
	    viewBox="0 0 455 455" >
      <path d="M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M276.772,334.411
      	l-21.248,21.178L127.852,227.5L255.524,99.411l21.248,21.178L170.209,227.5L276.772,334.411z"/>
      </svg>
    </div>
  )
}
